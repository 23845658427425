import React, { useState } from "react";
import {
 TextField,
  Dialog,
  DialogContent
} from "@material-ui/core"
import logoIcon from '../assets/images/login/logo.svg'
import PhoneInput from 'react-phone-input-2'
import close_image from "../assets/images/icon-close.png";
import axios from 'axios'
import apiUrls from '../api/apiUrl'
// import { message } from 'antd';
// import 'antd/lib/message/style/index.css'
import BounceIndicator from "react-activity/lib/Bounce"
import OtpInput from 'react-otp-input';
import { navigate } from "gatsby";
import fbIcon from '../assets/images/login/fb.svg'
import linkedinIcon from '../assets/images/login/linkedin.svg'
import googleIcon from '../assets/images/login/google.svg'
import columnImage1 from '../assets/images/Group_3436.png'
import columnImage2 from '../assets/images/Two-factor-authentication-bro@2x.png'
import columnImage3 from '../assets/images/Subscriber-bro@2x.png'
import columnImage4 from '../assets/images/wrongOTP.png'
import LoadingButton from '@mui/lab/LoadingButton';
import { useQuery, gql } from "@apollo/client"
import { STUDY_ABROAD_EXAM_QUERY, JOURNEY_STEP_QUERY } from './graphql'
import apiUrl from "../api/apiUrl";
import $, { data } from "jquery"

export default function FormDialog({
  open,
  setOpen,
  login,
  setLogin,
  errorMessage,
  setErrorMessage,
  successMessage,
  setSuccessMessage,
  phoneError,
  setphoneError
}){

  const [value, setValue] = useState('');
  // const phoneInputRef = React.useRef(null);
  const [otp, setotp] = useState('');
  const [isOtpSent, setisOtpSent] = useState(false);
  const [pageLoading, setpageLoading] = useState(false);
  const [isNewUser,setIsNewUser] = useState(false);

  const [email, setEmail] = useState();
  const [userName, setUserName] = useState();
  const [phone, setphone] = useState();
  const [firstName, setfirstName] = useState();
  const [lastName, setlastName] = useState();

  const [emailError, setemailError] = useState("");
  const [firstNameError, setfirstNameError] = useState("");
  const [lastNameError, setlastNameError] = useState("");
  const [serverError, setserverError] = useState('')

  const [isloading, setisloading] = useState(false);

  const allJourney = useQuery(JOURNEY_STEP_QUERY);
  const allStudyAbroadExam = useQuery(STUDY_ABROAD_EXAM_QUERY);

  const handleGoogle = async () => {
    navigate(`${process.env.DEV_BACKEND_URL}/connect/google`);
  };

  const handleFacebook = async () => {
      navigate(`${process.env.DEV_BACKEND_URL}/connect/facebook`);
  };

  const handleLinkedin = async () => {
      navigate(`${process.env.DEV_BACKEND_URL}/connect/linkedin`);
  };

  const formValidate = () => {
    setfirstNameError('')
    setlastNameError('')
    setemailError('')
    setSuccessMessage("");
    setErrorMessage("");
    setphoneError("");
    if (!firstName) {
      setfirstNameError('First name is Required')
      console.log(firstName)
      console.log('ferr')
      return null
    }
    if (!lastName) {
      setlastNameError('Last name is Required')
      console.log('lerr')

      return null
    }


    if (!email) {
      setemailError('Email Required')
      console.log('eerr')
      return null
    }

    var emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

    if (email && !email.match(emailRegex)) {
      setemailError('Invalid email')
      console.log('emerr')

      return null
    }

    updateUserInfos()

  }

  const updateUserInfos = async () => {
    setisloading(true)

    const journeyList = [];
    const studyAbroadExamList = [];
    allJourney.data.journeySteps.forEach((record) => {
      let dict = {};
      dict.journeystep = record.id;
      journeyList.push(dict);
    });
    allStudyAbroadExam.data.studyAbroadExams.forEach((record) => {
      let dict = {};
      dict.studyAbroadExam = record.id;
      studyAbroadExamList.push(dict);
    });
    try {
      const hader = {
        headers: {
          Authorization: localStorage.getItem('token') ? `Bearer ${localStorage.getItem('token')}` : '',
        }
      }

      const data = {
        firstName: firstName,
        lastName: lastName,
        username: firstName+" "+lastName,
        email: email,
        journeyStepContent: journeyList,
        studyAbroadExamContent: studyAbroadExamList
      }
      setUserName(firstName+" "+lastName)
      var response = await axios.post(apiUrl.updateUser, data, hader)
      console.log("ressssssssss",response,response?.data?.errmsg)
      setserverError(response?.data?.errmsg || '')
      setisloading(response?.data?.status ? true : false)
      localStorage.setItem("userName", userName);
      console.log("---------------called from complete profile success------------------",serverError)
      if(!(response?.data?.errmsg)){
        if(localStorage.getItem('lastPage') ==='blog-index'){
          navigate("/blog/")
          localStorage.removeItem('lastPage');
          if (typeof window !== `undefined`) {
            if (typeof document !== `undefined`) {
              window.location.reload();
            }
          }
        }
        else if(localStorage.getItem('lastPage') ==='blog-detail'){
          if(localStorage.getItem('lastPageUrl')!='null' || localStorage.getItem('lastPageUrl')){
            navigate(`/blog/${localStorage.getItem('lastPageUrl')}`)
          }
          else{
            navigate("/blog/")
          }
          localStorage.removeItem('lastPage');
          localStorage.removeItem('lastPageUrl');
          if (typeof window !== `undefined`) {
            if (typeof document !== `undefined`) {
              window.location.reload();
            }
          }
        }
        else if(localStorage.getItem('lastPage') ==='tools'){
          if(sessionStorage.getItem('userRedirectFlag')){
            navigate('/tools/results')
            sessionStorage.setItem('activeStep',6);
          }
          else{
            navigate("/user-profile/")
          }
          localStorage.removeItem('lastPage');
        }else if(localStorage.getItem('lastPage')==='pricing-summary'){
          navigate('/pricing/summary')
        }
        else{
          localStorage.removeItem('lastPage');
          localStorage.removeItem('lastPageUrl');
          navigate("/user-profile/")
        }
      }
    } catch (error) {
      console.log(error)
      setisloading(false)
      localStorage.setItem("userName", userName);
      console.log("---------------called from complete profile catch------------------")
      if(localStorage.getItem('lastPage') ==='blog-index'){
        navigate("/blog/")
        localStorage.removeItem('lastPage');
        if (typeof window !== `undefined`) {
          if (typeof document !== `undefined`) {
            window.location.reload();
          }
        }
      }
      else if(localStorage.getItem('lastPage') ==='blog-detail'){
        if(localStorage.getItem('lastPageUrl')!='null' || localStorage.getItem('lastPageUrl')){
          navigate(`/blog/${localStorage.getItem('lastPageUrl')}`)
        }
        else{
          navigate("/blog/")
        }
        localStorage.removeItem('lastPage');
        localStorage.removeItem('lastPageUrl');
        if (typeof window !== `undefined`) {
          if (typeof document !== `undefined`) {
            window.location.reload();
          }
        }
      }
      else if(localStorage.getItem('lastPage') ==='tools'){
        if(sessionStorage.getItem('userRedirectFlag')){
          navigate('/tools/results')
          sessionStorage.setItem('activeStep',6);
        }
        else{
          navigate("/user-profile/")
        }
        localStorage.removeItem('lastPage');
      }else if(localStorage.getItem('lastPage')==='pricing-summary'){
        
        navigate('/pricing/summary')
      }
      else{
        localStorage.removeItem('lastPage');
        localStorage.removeItem('lastPageUrl');
        navigate("/user-profile/")
      }
    }
  }

  const handleClose = () => {
    setOpen(false);
    setSuccessMessage("");
    setErrorMessage("");
    setotp("");
    setphoneError("");
    setisOtpSent(false);
    setValue("");
    setpageLoading(false);
    console.log("111111111111+++",isNewUser)
    if(isNewUser){
      if (typeof window !== `undefined`) {
        if (typeof document !== `undefined`) {
          setIsNewUser(false)
          window.location.reload();
        }
      }
    }
    else{
      setIsNewUser(false)
    }
  };

  const sendOtp = async () => {
      setSuccessMessage("")
      setErrorMessage("")
      setphoneError("");
      setpageLoading(true)
      try {
          var response = await axios.post(apiUrls.login, { phone: value })
          response?.data?.status ?setSuccessMessage(response?.data?.message || 'Otp Sent Successfullty'):setErrorMessage(response?.data?.message || 'Failed to Send Otp')
          response?.data?.status && setisOtpSent(true)
      } catch (error) {
          console.log(error)
          setErrorMessage(response.data.message || 'Failed to Send Otp')

      } finally {
          setpageLoading(false)
      }
  }

  const regSendOtp = async () =>{
    console.log("register clicked!!!!!!!!!!!!!!!!!!!")
    setSuccessMessage("")
    setErrorMessage("")
    setphoneError("");
    setpageLoading(true)
    setphoneError(value ? value.length < 10 ? 'Phone Number Is Invalid' : '' : 'Phone Number Required')
    if (phoneError) {
        return null
    }
    try {
        var response = await axios.post(apiUrls.register, { phone: value })
        response?.data?.status ? setSuccessMessage(response?.data?.message || 'Otp Sent Successfullty', 5) : setErrorMessage(response?.data?.message || 'Failed to Send Otp')
        response?.data?.status && setisOtpSent(true)
        console.log("11111111111111111111111111111aaaaaaaaaaaaaaaaaaaaaaaaaa")
    } catch (error) {
        console.log(error)
        setErrorMessage(response.data.message || 'Failed to Send Otp')

    } finally {
        setpageLoading(false)
    }
  }

  const verifyOtp = async (otpTemp) => {
    setpageLoading(true)
    setSuccessMessage("")
    setErrorMessage("")
    setphoneError("")
    if (!value || !otpTemp) { return null }
    console.log("otpppppppppppppppppppp",otpTemp)
    try {
        var response = await axios.post(apiUrls.verify, { phone: value, otp: otpTemp })
        console.log("===============111111111----",response?.data?.messgae)
        if (response?.data?.messgae) {
            return setErrorMessage(response.data.messgae || 'Otp verification failed')
        }
        console.log("11111111111111111111111111111bbbbbbbbbbbbbbbbbbbbbbbbbb",response?.data?.status,"---",response?.data?.jwt)
        if (response?.data?.status && response?.data?.jwt) {
          console.log("11111111111111111111111111111ccccccccccccccccccccccccccccccccccc")
          localStorage.setItem("token", response?.data?.jwt);
          localStorage.setItem("userId", response?.data?.user?.id);
          localStorage.setItem("userName", response?.data?.user?.username || ' ')
          localStorage.setItem("firstName", response?.data?.user?.firstName || '')
          console.log("1111111111111111111ddddddddddddddd",response?.data?.user?.firstName)
          if(response?.data?.user?.firstName){
            console.log("111111111111111111111111111ffffffffffffffffffff")
            setIsNewUser(false)
            localStorage.setItem("sessionSetupTime", new Date())
            console.log("---------------called from login component------------------",localStorage.getItem('lastPage'))
            if(localStorage.getItem('lastPage') ==='home'){
              setOpen(false)
              setLogin(false)
              localStorage.removeItem('lastPage');
              if (typeof window !== `undefined`) {
                if (typeof document !== `undefined`) {
                  window.location.reload();
                }
              }
            }
            else if(localStorage.getItem('lastPage') ==='blog-index'){
              navigate("/blog/")
              setOpen(false)
              setLogin(false)
              localStorage.removeItem('lastPage');
              if (typeof window !== `undefined`) {
                if (typeof document !== `undefined`) {
                  window.location.reload();
                }
              }
            }
            else if(localStorage.getItem('lastPage') ==='blog-detail'){
              if(localStorage.getItem('lastPageUrl')!='null'){
                navigate(`/blog/${localStorage.getItem('lastPageUrl')}`)
              }
              else{
                navigate("/blog/")
              }
              setOpen(false)
              setLogin(false)
              localStorage.removeItem('lastPage');
              localStorage.removeItem('lastPageUrl');
              if (typeof window !== `undefined`) {
                if (typeof document !== `undefined`) {
                  window.location.reload();
                }
              }
            }
            else if(localStorage.getItem('lastPage') ==='tools'){
              if(sessionStorage.getItem('userRedirectFlag')){
                navigate('/tools/results')
                sessionStorage.setItem('activeStep',6);
              }
              else{
                navigate("/user-profile/")
              }
              localStorage.removeItem('lastPage');
            }else if(localStorage.getItem('lastPage')==='pricing-summary'){
              navigate('/pricing/summary');
              setOpen(false)
              setLogin(false)
             
              localStorage.removeItem('lastPage');
              if (typeof window !== `undefined`) {
                if (typeof document !== `undefined`) {
                  window.location.reload();
                }
              }
            }
            else if(localStorage.getItem('lastPage')==='university'){
              navigate("/universities/")
              setOpen(false)
              setLogin(false)
              localStorage.removeItem('lastPage');
              if (typeof window !== `undefined`) {
                if (typeof document !== `undefined`) {
                  window.location.reload();
                }
              }
            }
            else if(localStorage.getItem('lastPage')==='university-detail'){
              if(localStorage.getItem('lastPageUrl')!='null'){
                navigate(`/universities/${localStorage.getItem('lastPageUrl')}`)
              }
              else{
                navigate("/universities/")
              }
              setOpen(false)
              setLogin(false)
              localStorage.removeItem('lastPage');
              localStorage.removeItem('lastPageUrl');
              if (typeof window !== `undefined`) {
                if (typeof document !== `undefined`) {
                  window.location.reload();
                }
              }
            }
            else if(localStorage.getItem('lastPage') === 'housing-page'){
              if(localStorage.getItem('lastPageId')!='null'){
                navigate(`/house/?id=${localStorage.getItem('lastPageId')}`)
              }
              else{
                navigate("/house/")
              }
              setOpen(false)
              setLogin(false)
              localStorage.removeItem('lastPage');
              localStorage.removeItem('lastPageId');
              if (typeof window !== `undefined`) {
                if (typeof document !== `undefined`) {
                  window.location.reload();
                }
              }
            }
            else if(localStorage.getItem('lastPage') === 'blog-topics'){
              if(localStorage.getItem('topicSlug')!='null'){
                navigate(`/blog/topic/${localStorage.getItem('topicSlug')}`)
              }
              else{
                navigate("/blog/")
              }
              setOpen(false)
              setLogin(false)
              localStorage.removeItem('lastPage');
              localStorage.removeItem('topicSlug');
              if (typeof window !== `undefined`) {
                if (typeof document !== `undefined`) {
                  window.location.reload();
                }
              }
            }
            else{
              localStorage.removeItem('lastPage');
              localStorage.removeItem('lastPageUrl');
              navigate("/user-profile/")
            }
          }
          else{
            console.log("111111111111111111eeeeeeeeeeeeeeeee",response?.data?.user?.firstName)
            setIsNewUser(true)
          }
        }

    } catch (error) {
        console.log(error)
        setErrorMessage(response.data.messgae || 'Otp verification failed')
    } finally {
        setpageLoading(false)

    }
  }

  React.useEffect(() => {
    console.log("useEffect called");
    // phoneInputRef.current.focus();
    setSuccessMessage("")
    setErrorMessage("")
    setphoneError("")
  }, []);

  const handleOTPNumber = e =>{
    setotp(e)
    if(e.length ===6){
      verifyOtp(e)
    }
  }

  return (
    <div>
      <Dialog
        className={isNewUser ?"login-signup-popup box-signup-form":"login-signup-popup"}
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogContent className="sign-popup">
          <div className="box">
            <div className="image-column">
              {isNewUser?
                <div class="signup-image-column position-relative text-center login-m-image">
                  <div class="column-image">
                    <img src={columnImage3} alt="OTP Image"/>
                  </div>
                  <div class="colum-content">
                    <span>Sign up to receiving 360° student services.</span>
                  </div>
                </div>
              :
              !isOtpSent ?
              <div class="login-image-column position-relative text-center login-m-image">
                  <img src={columnImage1} alt="Login Image"/>
              </div>
              : errorMessage === "Invalid Otp"?
              <div class="wrong-otp-image-column position-relative text-center login-m-image">
                  <div class="column-image">
                    <img src={columnImage4} alt="OTP Image"/>
                  </div>
                  <div class="colum-content">
                    <span>Seems like you have entered a wrong OTP.</span>
                  </div>
              </div>
              :
              <div class="otp-image-column position-relative text-center login-m-image">
                 <div class="colum-image">
                  <img src={columnImage2} alt="OTP Image"/>
                </div>
                <div class="colum-content">
                  <span>We have sent you a One Time Password (OTP)</span>
                </div>
              </div>
            }
            </div>
            <div class={isNewUser ?"col-md-6 position-relative text-center login-m-show signup-form"
                    :"col-md-6 position-relative text-center login-m-show"}>
                <div class="login-right" >
                    <img class="login-logo" src={logoIcon} />
                    {isNewUser?
                      <h4>You're almost done!</h4>
                      :login && !isOtpSent?
                        <h4>Log in to explore, discover &amp; learn to study in France.</h4>
                      :isOtpSent && !errorMessage?
                        <h4>Enter OTP sent to your mobile number</h4> 
                      :isOtpSent && errorMessage === "Invalid Otp"?
                        <h4>Uh-oh! Incorrect OTP.Try again</h4>
                      :
                        <h4>Sign Up to get the most of Dimensions France</h4>
                    }
                    {isNewUser?
                    <>
                      <span className="text-danger">{ serverError }</span>
                      <div style={{ marginTop: "20px" }}>
                        <div className="form-field">
                          <span className="label-name">First Name</span>
                          <TextField
                            margin="dense"
                            id="firstname"
                            type="text"
                            fullWidth
                            value={firstName}
                            required
                            onChange={e => setfirstName(e.target.value)}
                            helperText={firstNameError ? firstNameError : ""}
                            error={firstNameError.length > 0}
                          />
                        </div>
                        <div className="form-field">
                          <span className="label-name">Last Name</span>
                          <TextField
                            margin="dense"
                            id="LastName"
                            type="text"
                            required
                            fullWidth
                            value={lastName}
                            onChange={e => setlastName(e.target.value)}
                            helperText={lastNameError ? lastNameError : ""}
                            error={lastNameError.length > 0}
                          />   
                        </div> 
                        <div className="form-field">
                          <span className="label-name">Email</span>                  
                          <TextField
                            // autoFocus
                            margin="dense"
                            id="email"
                            type="text"
                            value={email}
                            fullWidth
                            required
                            onChange={e => {
                              setEmail(e.target.value)
                            }}
                            helperText={emailError ? emailError : ""}
                            error={emailError.length > 0}
                          />
                        </div>
                      </div>
                      <LoadingButton 
                        variant="contained" 
                        loading={isloading} 
                        className="loading-button-style"
                        style={{
                          background: 'transparent',
                          backgroundColor: '#0055a4',
                          border: '4px solid #0055a4',
                          borderRadius: 25,
                          boxShadow: 'none',
                          textTransform: 'uppercase',
                          color:' #fff',
                          fontWeight: 600,
                          width: 'auto',
                          height: '45px',
                          minWidth: '120px',
                          fontSize: '15px',
                          padding: '6px 20px 3px',
                          letterSpacing: 1.04,
                          fontFamily: "helveticaregular",
                          paddingTop: 8
                        }}
                        onClick={formValidate} >
                        SIGN UP
                      </LoadingButton>
                    </>
                    :
                    <>
                    {!isOtpSent ?
                        <div class="form-group row m-0 ">
                            <div class="col-md-12 m-auto text-start fw-bold">
                                {/* <input type="text" class="form-control" /> */}
                                <PhoneInput
                                    country={'in'}
                                    enableSearch={true}
                                    value={value}
                                    onChange={phone => setValue(phone)}
                                    className="text-start"
                                    inputProps={{
                                      autoFocus: true
                                    }}
                                    containerClass='w-100 text-start fw-bold'
                                    placeholder={'Mobile Number'}
                                />
                                {successMessage? <div className="success-message text-center">{successMessage}</div>:""}
                                { phoneError ==="Phone Number Required"?
                                <> {phoneError && <div className='error-message 2 text-center'>{phoneError}</div>} </>
                                :
                                <> {errorMessage? <div className="error-message 1 text-center">{errorMessage}</div>:""}
                                   {phoneError && <div className='error-message 2 text-center'>{phoneError}</div>}
                                </>
                                }
                                <div className="submit">
                                    {pageLoading ? 
                                      <button disabled onClick={() => sendOtp()} >
                                        <BounceIndicator size={25} color={"#FFFF"} /> 
                                      </button>
                                    : login?
                                    <div className="cta-primary">
                                      <button onClick={() => sendOtp()} >SEND </button></div>:
                                    <div className="cta-primary">  <button onClick={() => regSendOtp()} >SEND </button></div>
                                    }

                                </div>
                            </div>
                        </div> :
                        <div class="form-group row m-0">
                            <div class="col-md-12 otp-field">
                                <OtpInput
                                  className="justify-content-center"
                                    value={otp}
                                    inputStyle={{
                                        width: '2rem',
                                        height: '2rem',
                                        fontSize: '1rem',
                                        borderRadius: 4,
                                        margin: '5px',
                                        border: '2px solid rgba(0,0,0,0.3)',
                                    }}
                                    onChange={e => 
                                       handleOTPNumber(e)}
                                    numInputs={6}
                                    separator={<span>-</span>}
                                    shouldAutoFocus={true}
                                />
                                {successMessage? <span className="success-message">{successMessage}</span>:""}
                                { phoneError ==="Phone Number Required"?
                                <> {phoneError && <div className='error-message 2 text-center'>{phoneError}</div>} </>
                                :
                                <> {errorMessage? <div className="error-message 1 text-center">{errorMessage}</div>:""}
                                   {phoneError && <div className='error-message 2 text-center'>{phoneError}</div>}
                                </>
                                }
                                <div className="submit">
                                    {pageLoading ? <button onClick={() => verifyOtp(otp)}><BounceIndicator size={25} color={"#FFFFFF"} /> </button>
                                        : <>
                                            <p class="login-link"> If you didn't receive code, click <span onClick={() => sendOtp()} style={{ cursor:"pointer",color: "#FF0000" }}> Resend</span></p>
                                            <div className="cta-primary"><button onClick={() => verifyOtp(otp)}>VERIFY</button></div>
                                        </>}

                                </div>
                            </div>
                        </div>
                    }

                    <p class="login-link"> 
                    {login
                      ? "Don't Have An Account Yet?" + " "
                      : "Already Have An Account?" + " "} 
                      <span style={{ color: "#1e69b5" }}> 
                        {/* <Link to="/register" className='text-decoration-none'>REGISTER</Link> */}
                        {login ? (
                            <button
                              className='text-decoration-none button-no-style'
                              onClick={() => {
                                setLogin(false);
                                setisOtpSent(false);
                                setSuccessMessage("");
                                setErrorMessage("");
                                setphoneError("");
                                setotp("");
                                setValue("+91");
                              }}
                            >
                              SIGN UP{" "}
                            </button>
                      ) : (
                        <button
                          className='text-decoration-none button-no-style'
                          onClick={() => {
                            setLogin(true);
                            setisOtpSent(false);
                            setSuccessMessage("");
                            setErrorMessage("");
                            setphoneError("");
                            setotp("");
                            setValue("+91");
                          }}
                        >
                          LOGIN{" "}
                        </button>
                      )}
                      </span>
                    </p>
                    <p class="login-link">Or Connect With</p>
                    <img class="login-icon" src={fbIcon} alt="" onClick={() => { handleFacebook() }} style={{ cursor: 'pointer' }} />
                    <img class="login-icon" src={googleIcon} alt="" onClick={() => { handleGoogle() }} style={{ cursor: 'pointer' }} />
                    <img class="login-icon" src={linkedinIcon} alt="" onClick={() => { handleLinkedin() }} style={{ cursor: 'pointer' }} />
                    </>
                  }
                </div>
            </div>
          </div>
        </DialogContent>
        <img
          className="closeicon"
          onClick={() => {
            handleClose();
          }}
          src={close_image}
          alt=""
        />
      </Dialog>
    </div>
  );
}
