/* eslint-disable no-unused-expressions */
import * as React from "react"
// import PropTypes from "prop-types"
// import { withPrefix } from "gatsby"
// import { useStaticQuery, graphql } from "gatsby"
import { Helmet } from "react-helmet"
import Header from "./header"
import Footer from "./footer"
import "../assets/css/univ_list.css"
import "../assets/css/univ-details.css"
import "../assets/css/progress.css"
import "./layout.css"
import "../assets/css/home.css"
import "../assets/css/study_detail.css"

import "../assets/bootstrap/css/bootstrap.min.css"
// import '../assets/bootstrap/js/bootstrap.min.js'
// import '../assets/js/jquery.min.js'
import "../assets/less/home.less"
// import '../assets/js/menu.js'
import "../assets/css/custom.css"
import "../assets/css/user.css"
import "../assets/css/blog.css"
import "../assets/css/login.css"
import "../assets/fontawesome/css/all.min.css"
import $ from "jquery"


function Layout(props) {
  React.useEffect(() => {
    // let isMounted = true;
    if (typeof window !== `undefined`) {
      if (typeof document !== `undefined`) {
        // console.log("indexxxxxxxxxxxxxxxx",$(".progtrckr li.active").index())
        if ($(".progtrckr li.active").index() != 0) {
          $('.tool-main-search-box').remove();
        }
        $(document).ready(function () {
          
          $("li.progtrckr-todo").filter(function () {
            // console.log("----------------", $(this).text())
            return $(this).text()[0] === '5';
          })
            .remove();
          $("li.progtrckr-todo").filter(function () {
            // console.log("----------------", $(this).text())
            return $(this).text()[0] === '6';
          }).html('<em>5</em><span>Results</span>');
          // console.log("++++++++++++++", $("li.progtrckr-todo"))
          // Humburger menu
          $(".humburger-menu-click").click(function () {
            $("#bar").toggleClass("fa-times")
            $(".menu-items ").toggle("slow")
          })
          {
            /* if ($(".home-banner-content .banner-section").length) {
        $('.home-banner-content .banner-section.move').removeClass('move');
      } */
          }

          // $(window).scroll(function() {
          //     if ($(this).scrollTop() >= 70) { // this refers to window
          //         $('.header-menu').addClass('sticky');
          //     }
          // });
          if ($(window).width() > 767) {
            $(window).scroll(function () {
              var scroll = $(window).scrollTop()

              if (scroll >= 120) {
                $(".header-menu").addClass("fixed")
                $("body").addClass("scrolled")
                $("#header").hide()
                $("#stickyheader").show()
              } else {
                $(".header-menu").removeClass("fixed")
                $("#header").show()
                $("body").removeClass("scrolled")
              }
            })
          }

          $(window).scroll(function () {
            var scroll = $(window).scrollTop()
            if (scroll >= 500) {
              $("body").addClass("overlay-content")
            } else {
              $("body").removeClass("overlay-content")
            }
          })
          $(document.links)
            .filter(function () {
              return this.hostname != window.location.hostname
            })
            .attr("target", "_blank")
          // if ( $(window).width() > 991 ) {
          //   $(window).scroll(function() {
          //     var scroll = $(window).scrollTop();
          //     var distance = $('.consultation-section').offset().top;
          //     // $window = $(window);
          //     var outerContentsHeight = $('.home-banner.study-detail').height();
          //     var outerContentsHeight1= outerContentsHeight - 50;
          //     console.log(outerContentsHeight1);
          //     var scroll1 = $(window).scrollTop() + 400;
          //     if (scroll >= outerContentsHeight1) {
          //         $('.home.study').addClass('fixed-section');
          //     } else {
          //         $('.home.study').removeClass('fixed-section');
          //     }
          //     if ( scroll1 >= distance ) {
          //       $('.home.study').removeClass('fixed-section');
          //     }

          //   });
          // }
        });
        // Add the WhatsApp widget script dynamically
        (function (w, d, s, u) {
          w.gbwawc = {
            url: u,
            options: {
              waId: `${process.env.GALLABOX_WHATSAPP_NUM}`,
              siteName: "Dimensions France",
              siteTag: "Usually replies in 4 minutes",
              siteLogo: `${process.env.DIMENSIONS_WHATSAPP_LOGO}`,
              widgetPosition: "RIGHT",
              triggerMessage: "",
              welcomeMessage: "Thank you for contacting Dimensions France. How can we assist you?",
              brandColor: "#25D366",
              messageText: "",
              replyOptions: ["", ""],
            },
          };
          const h = d.getElementsByTagName(s)[0];
          const j = d.createElement(s);
          j.async = true;
          j.src = u + "/whatsapp-widget.min.js?_=" + Math.random();
          h.parentNode.insertBefore(j, h);
        })(window, document, "script", "https://waw.gallabox.com");
      }
    }
    // return () => { isMounted = false };
  }, [])
  return (
    <>
      <Helmet>
        <script
          src="https://ajax.googleapis.com/ajax/libs/jquery/3.5.1/jquery.min.js"
          type="text/javascript"
        ></script>
         <link
        rel="stylesheet"
        href="https://cdn.jsdelivr.net/npm/bootstrap@4.1.3/dist/css/bootstrap.min.css"
      />
        {/* useScript("https://ajax.googleapis.com/ajax/libs/jquery/3.5.1/jquery.min.js")
  useScript(
    "https://cdn.jsdelivr.net/npm/bootstrap@4.1.3/dist/js/bootstrap.min.js"
  ) */}
      <script src="https://cdn.jsdelivr.net/npm/bootstrap@4.1.3/dist/js/bootstrap.min.js"></script>
      </Helmet>
      <div>

        {props.setTitle !== "Accommodation Detail" ? (
          <Header siteTitle={props.setTitle || `Title`}  tag={props.tag}/>
        ) : props.changeHeader === false ? (<Header siteTitle={props.setTitle || `Title`} />) : null}
        <main>{props.children}</main>
        <Footer />
      </div>
    </>
  )
}
export default Layout
