import React, { useState }  from "react"
import { FOOTER } from "../components/graphql"
import { useQuery } from "@apollo/client"
import { Link } from "gatsby"
import parse from "html-react-parser"

function Footer() {
  const footer = useQuery(FOOTER)

  const renderMenu =() =>{
    return(
      <>
      {footer.data ?
      <>
        <div className="f-list links">
          <ul>
            {footer.data.footerBlock.menuContent.map(
              (content, index) => (
                <>
                  {index === 0 ||
                  index <
                    footer.data.footerBlock.menuContent.length / 2 ? (
                    <li key={content.id}>
                   
                          {(localStorage.getItem("userId") === null) ? (
                            <>
                              <Link to={content.menuURL} 
                                target={content.menuName ==="Contact Us"?"_blank":"_self"}>
                                {content.menuName}
                              </Link>
                            </>
                            )
                            : <>
                              {content.menuURL !=='/login' ?
                                <>
                                  {content.menuURL !=='/register'?
                                    <Link to={content.menuURL}
                                    target={content.menuName ==="Contact Us"?"_blank":"_self"}>
                                      {content.menuName}
                                    </Link>
                                  :null}
                                </>
                              :null}
                              </>}

                     </li>
                  ) : null}
                </>
              )
            )}
          </ul>
        </div>
        <div className="f-list links last">
          <ul>
            {footer.data.footerBlock.menuContent.map(
              (content, index) => (
                <>
                  {index >=
                  footer.data.footerBlock.menuContent.length / 2 ? (
                    <li key={content.id}>
                     {(localStorage.getItem("userId") === null) ? (
                        <>
                          <Link to={content.menuURL}
                           target={content.menuName ==="Contact Us"?"_blank":"_self"}>
                            {content.menuName}
                          </Link>
                        </>
                        )
                        : <>
                        {content.menuURL !=='/login' ?
                          <>
                            {content.menuURL !=='/register'?
                              <Link to={content.menuURL}
                              target={content.menuName ==="Contact Us"?"_blank":"_self"}>
                                {content.menuName}
                              </Link>
                            :null}
                          </>
                        :null}
                      </>}
                      </li>
                  ) : null}
                </>
              )
            )}
          </ul>
        </div>
        </>
        :null
        }
      </>
    )
  }
  return (
    <footer>
      {footer.data ? (
        <div className="footer">
          <div className="container">
            <div className="footer-top">
              <div className="left-top"></div>
              <div className="footer-content">
                <div className="f-list">
                  <img
                    src={
                      footer.data.footerBlock.logoImage
                        ? process.env.BACKEND_URL +
                          footer.data.footerBlock.logoImage.url
                        : null
                    }
                    alt={
                      footer.data.footerBlock.companyName
                        ? footer.data.footerBlock.companyName
                        : ""
                    }
                  />
                  <div className="f-title">
                    {footer.data.footerBlock.companyName
                      ? footer.data.footerBlock.companyName
                      : ""}
                  </div>
                  <p>
                    {footer.data.footerBlock.companySlogan
                      ? footer.data.footerBlock.companySlogan
                      : ""}
                  </p>
                  <ul className="f-share">
                    {footer.data.footerBlock.socialMediaContent.map(
                      (content, index) => (
                        <li key={content.id}>
                          <Link to={content.socialIconURL} target="_blank">
                            <img
                              src={
                                content.socialMediaIcon
                                  ? process.env.BACKEND_URL +
                                    content.socialMediaIcon.url
                                  : null
                              }
                              alt=""
                            />
                          </Link>
                        </li>
                      )
                    )}
                  </ul>
                </div>
                
                {renderMenu()}
                <div className="f-list">
                  <div className="em">
                    {footer.data.footerBlock.websiteURL
                      ? footer.data.footerBlock.websiteURL
                      : ""}
                  </div>

                  {footer.data.footerBlock.contactInfo.map((content, index) => (
                    <>
                      <div key={content.id} className="addr-mail">
                        <div className="mail">
                          {content.mailAddress ? content.mailAddress : ""}
                        </div>
                        <div className="mob">
                          {content.contactNumber ? content.contactNumber : ""}
                        </div>
                      </div>
                    </>
                  ))}

                  {/*<div className="address">
                                  {footer.data.footerBlock.companyAddress?
                                    parse(footer.data.footerBlock.companyAddress)
                                    :""
                                    }
                                  </div>*/}
                  {/*<Link to={footer.data.footerBlock.mapURL} style={{textDecoration:"none"}}>
                                    <div className="map">{footer.data.footerBlock.mapButtonName}</div>
                                  </Link>*/}
                </div>
              </div>
              <div className="right-bottom"></div>
            </div>
          </div>
          <div className="footer-bottom">
            <p className="text-center">
              &copy;{" "}
              {footer.data.footerBlock.copyRightContent
                ? footer.data.footerBlock.copyRightContent
                : ""}{" "}
              |{" "}
              {footer.data.footerBlock.copyRightYear
                ? footer.data.footerBlock.copyRightYear
                : ""}
            </p>
          </div>
        </div>
      ) : null}
    </footer>
  )
}
export default Footer
