import React, { useState } from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";
import dimensions_logo from "../assets/images/dimensions_logo.png";
import sticky_logo from "../assets/images/sticky_logo.png";
import Login from "./login";
import Button from "@material-ui/core/Button";
import { navigate } from "gatsby";
// import { render } from "react-dom"
import { DropdownButton, Dropdown } from "react-bootstrap";
// import { useQuery } from "@apollo/client"
// import {
//   GET_USER_NAME_QUERY
// } from "../components/graphql"
import cookies from "js-cookies";
import Cookies from "js-cookie";
import { useMutation } from "@apollo/client";
import {
  GET_USER_ID_MUTATION,
  AUTO_LOGIN_USER_MUTATTION,
} from "../components/graphql";

const Header = ({ siteTitle,tag }) => {
  const [open, setOpen] = useState(false);
  const [login, setLogin] = useState(false);
  const [forgotPassword, setForgotPassword] = useState(false);
  const [username, setUsername] = useState(null);
  const [first, setfirst] = useState('')
  const [userID, setUserID] = useState(null);
  const [studyInFrance, setStudyInFrance] = useState(tag ==='study-in-france'?true:false);
  const [services,setServices]= useState(tag ==='services'?true:false);
  const [universities, setUniversities] = useState(tag ==='universities'?true:false);
  const [aboutUs, setAboutUs] = useState(tag ==='about-us'?true:false);
  const [blog, setBlog] = useState(tag ==='blog'?true:false);
  const [housing, setHousing] = useState(tag ==='housing'?true:false);
  const [askme, setAskme] = useState(tag ==='ask-me'?true:false);
  const [tools,setTools] = useState(tag ==='tools'?true:false);
  const [getUser] = useMutation(GET_USER_ID_MUTATION);
  const [loginUser] = useMutation(AUTO_LOGIN_USER_MUTATTION);
  const [phoneError, setphoneError] = useState('');
  const [successMessage,setSuccessMessage] = useState("");
  const [errorMessage,setErrorMessage] = useState("");
  // const userData = useQuery(GET_USER_NAME_QUERY, {
  //   variables: { id: userID }
  // })
  // console.log(userData)
  // if( userData.data){
  //   if(userData.data.user.firstName !== "null" && userData.data.user.firstName !== "" ){
  //     localStorage.setItem("userName", userData.data.user.firstName)
  //   }
  // }

  React.useEffect(() => {
    console.log("useEffect called");
    console.log(
      "11111111111111111111111111111111111111111111111111",
      cookies.keys()
    );
    console.log(
      cookies.getItem("username"),
      "1111111111",
      cookies.getItem("email"),
      "2222222222",
      cookies.getItem("password")
    );
    if (localStorage.getItem('sessionSetupTime')) {
      var tempTime = new Date(localStorage.getItem('sessionSetupTime'));
      tempTime.setHours(tempTime.getHours() + 1);
      var currentDateTime = new Date();
      // console.log("temp----------------------------",tempTime, currentDateTime)
      if (currentDateTime > tempTime) {
        localStorage.removeItem("userName");
        localStorage.removeItem("token");
        localStorage.removeItem("userId");
        localStorage.removeItem("sessionSetupTime");
        const isBrowser = typeof window !== "undefined";
        if (isBrowser) {
          window.location.href = "/";
        }
      }
    }
    if (cookies.getItem("nodebblogout")) {
      localStorage.removeItem("userName");
      localStorage.removeItem("token");
      localStorage.removeItem("userId");
      localStorage.removeItem("sessionSetupTime");
      Cookies.remove("nodebblogout", {
        path: "/",
      });
      const isBrowser = typeof window !== "undefined";
      if (isBrowser) {
        window.location.href = "/";
      }
    }
    if (
      cookies.getItem("username") &&
      cookies.getItem("email") &&
      cookies.getItem("autoLogin")
    ) {
      console.log(
        "-------------------------------------------",
        cookies.getItem("uid")
      );
      if (!cookies.getItem("uid") && !cookies.getItem("loggedin")) {
        console.log("22222222222222222222%%%%%%%%");
        try {
          loginUser({
            variables: {
              identifier: cookies.getItem("email"),
              autologin: cookies.getItem("autoLogin"),
            },
          })
            .then((response) => {
              console.log("reponse", response.data);
              localStorage.setItem("token", response.data.autoLogin.jwt);
              localStorage.setItem("userId", response.data.autoLogin.user.id);
              localStorage.setItem("userName", response?.data?.autoLogin?.user?.username)
              localStorage.setItem("firstName", response?.data?.autoLogin?.user?.firstName || '')
              var updateUserName = "";
              if (response.data.autoLogin.user.username) {
                updateUserName = response.data.autoLogin.user.firstName;
              }
              console.log(
                updateUserName,
                "lllllllllllllllllllllaaaaaaaaaaaaaaaaaaaaaaaa"
              );
              var userName = updateUserName ? updateUserName : "";
              // var userName = response.data.login.user.username
              var userId = response.data.autoLogin.user.id;
              localStorage.setItem("userName", userName);
              cookies.setItem("loggedin", "true");
              setUsername(userName);
              try {
                getUser({
                  variables: {
                    userid: userId,
                  },
                }).then((response) => {
                  console.log("reponse", response.data);
                  if (response.data.updateUser.user) {
                    if (response.data.updateUser.user.username) {
                      localStorage.setItem(
                        "userName",
                        response.data.updateUser.user.username
                      );
                      setUsername(response.data.updateUser.user.username);
                    }
                  }
                });
              } catch (e) {
                console.log("login user error", e);
              }
              localStorage.setItem("userId", userId);
              localStorage.setItem("sessionSetupTime", new Date());
              navigate("/user-profile/");
            })
            .catch((e) => {
              console.log("login user error", e);
            });
        } catch (e) {
          console.log("login user error", e);
        }
        // document.cookie="loggedin=true;"
      }
    }
    setUsername(localStorage.getItem("userName"));
    setfirst(localStorage.getItem("firstName"));
    setUserID(localStorage.getItem("userId"));
  }, []);

  const logOut = () => {
    localStorage.removeItem("userName");
    localStorage.removeItem("token");
    localStorage.removeItem("userId");
    localStorage.removeItem("sessionSetupTime");
    console.log("keyssssssssssss", cookies.keys());

    Cookies.remove("username", {
      path: "/",
    });
    Cookies.remove("autoLogin", {
      path: "/",
    });
    Cookies.remove("email", {
      path: "/",
    });
    Cookies.remove("loggedin", {
      path: "/",
    });
    Cookies.remove("n_username", {
      path: "/",
    });
    Cookies.remove("n_email", {
      path: "/",
    });
    Cookies.remove("n_uid", {
      path: "/",
    });
    Cookies.remove("express.sid", {
      path: "/askme",
    });
    setUserID(null);
    setUsername(null);
    const isBrowser = typeof window !== "undefined";
    if (isBrowser) {
      window.location.href = "/";
    }
  };
  return (
    <>
      <Login
        open={open}
        setOpen={setOpen}
        login={login}
        setLogin={setLogin}
        setUsername={setUsername}
        setUserID={setUserID}
        forgotPassword={forgotPassword}
        setForgotPassword={setForgotPassword}
        errorMessage ={errorMessage}
        setErrorMessage ={setErrorMessage}
        successMessage ={successMessage}
        setSuccessMessage ={setSuccessMessage}
        phoneError ={phoneError}
        setphoneError ={setphoneError}
      />
      <header>
        <div className="header-menu">
          <div className="header" id="header">
            <div className="container">
              <div className="row">
                {/* logo */}
                <div className="col-5 col-md-2 col-lg-2">
                  <div className="site-logo">
                    <Link to="/">
                      <img src={dimensions_logo} alt="Dimensions Logo"></img>
                    </Link>
                  </div>
                </div>
                {/* menus  */}
                <div className="col-7 col-md-10 col-lg-10">
                  <nav className="nav-menus">
                    <ul className="list list-unstyled">
                      <li
                        className={`list-item ${studyInFrance ? " active" : ""
                          }`}
                      >
                        <Link
                          className="nav-item"
                          to="/study-in-france/"
                          onClick={() => {
                            setStudyInFrance(true);
                            setUniversities(false);
                            setAboutUs(false);
                            setBlog(false);
                            setHousing(false)
                            setAskme(false)
                            setTools(false);
                            setServices(false);
                          }}
                        >
                          Study in France
                        </Link>
                      </li>
                      <li
                        className={`list-item ${services ? " active" : ""
                          }`}
                      >
                        <Link
                          className="nav-item"
                          to="/services/"
                          onClick={() => {
                            setServices(true);
                            setStudyInFrance(false);
                            setUniversities(false);
                            setAboutUs(false);
                            setBlog(false);
                            setHousing(false)
                            setAskme(false)
                            setTools(false)
                          }}
                        >
                          Services
                        </Link>
                      </li>
                      <li
                        className={`list-item`}
                      >
                        <Link
                          className="nav-item"
                          href={process.env.SHOP_URL}
                          target="_blank"
                          onClick={() => {
                            setServices(false);
                            setStudyInFrance(false);
                            setUniversities(false);
                            setAboutUs(false);
                            setBlog(false);
                            setHousing(false)
                            setAskme(false)
                            setTools(false)
                          }}
                        >
                          <span>Shop <sup className="blue">New</sup></span>
                        </Link>
                      </li>
                      {/* <li
                        className={`list-item ${housing ? " active" : ""
                          }`}
                      >
                        <Link
                          className="nav-item"
                          to="/housing/"
                          onClick={() => {
                            setServices(false);
                            setStudyInFrance(false);
                            setUniversities(false);
                            setAboutUs(false);
                            setBlog(false);
                            setHousing(true)
                            setAskme(false)
                            setTools(false)
                          }}
                        >
                          Housing
                        </Link>
                      </li> */}
                      <li
                        className={`list-item ${universities ? " active" : ""
                          }`}
                      >
                        <Link
                          className="nav-item"
                          to="/universities/"
                          onClick={() => {
                            setServices(false);
                            setStudyInFrance(false);
                            setUniversities(true);
                            setAboutUs(false);
                            setBlog(false);
                            setHousing(false)
                            setAskme(false)
                            setTools(false)
                          }}
                        >
                          Universities
                        </Link>
                      </li>
                      {/* <li
                        className={`list-item ${askme ? " active" : ""
                          }`}
                      >
                        <Link
                          className="nav-item"
                          href={process.env.NODEBB_URL}
                          target="_self"
                          onClick={() => {
                            setServices(false);
                            setStudyInFrance(false);
                            setUniversities(false);
                            setAboutUs(false);
                            setBlog(false);
                            setHousing(false)
                            setAskme(true)
                            setTools(false)
                          }}
                        >
                          Community
                        </Link>
                      </li> */}
                      <li className={`list-item ${blog ? " active" : ""}`}>
                        <Link
                          className="nav-item"
                          to="/blog/"
                          onClick={() => {
                            setStudyInFrance(false);
                            setAboutUs(false);
                            setBlog(true);
                            setUniversities(false)
                            setHousing(false)
                            setAskme(false)
                            setTools(false)
                            setServices(false);
                          }}
                        >
                          Blog
                        </Link>
                      </li>
                      <li className={`list-item ${tools ? " active" : ""}`}>
                        <DropdownButton id="signed" title={"Tools"}  className="nav-item" onClick={() => {
                            setStudyInFrance(false);
                            setUniversities(false)
                            setAboutUs(false);
                            setBlog(false);
                            setHousing(false)
                            setAskme(false)
                            setTools(true);
                            setServices(false)
                          }}>
                          {/* <Dropdown.Item
                            onClick={() => {
                              navigate("/pricing/");
                            }}
                          >
                            Pricing
                          </Dropdown.Item> */}
                          <Dropdown.Item
                            onClick={() => {
                              navigate("/tools/");
                            }}
                          >
                            Student Budget Calculator
                          </Dropdown.Item>
                        </DropdownButton>
                      </li>
                      {(username === null && userID === null) ||
                        (username === "" && userID === "") ? (
                        <>
                        { process.env.FILLOUT_ENQUIRY_FORM ?
                         <li className="pull-right">
                            <Link to= {`${process.env.FILLOUT_ENQUIRY_FORM}`}
                              className="btn mx-2 btn-advisor"
                            >
                              Ask an advisor{" "}
                            </Link>
                          </li> :null}
                        </>
                      ) : (
                        <li className="pull-right">
                          {/* <Button
                            className="btn btn-primary signed"
                            onClick={() => {
                              navigate("/user-profile/")
                            }}
                          >
                            {username}{" "}
                          </Button> */}
                          <DropdownButton id="signed" title={first}>
                            <Dropdown.Item
                              onClick={() => {
                                navigate("/user-profile/");
                              }}
                            >
                              Dashboard
                            </Dropdown.Item>
                            <Dropdown.Item
                              onClick={() => {
                                navigate("/edit-profile/");
                              }}
                            >
                              Edit Profile
                            </Dropdown.Item>
                            <Dropdown.Item onClick={logOut}>
                              Logout
                            </Dropdown.Item>
                          </DropdownButton>
                        </li>
                      )}
                    </ul>
                  </nav>
                  {/* mobile menu */}
                  <div className="mobile-menu nav-menus">
                    <div className="humburger-menu">
                      <span className="humburger-menu-click">
                        <i id="bar" className="fas fa-bars"></i>
                      </span>
                    </div>
                    <div className="mobile-advisor">
                    {(username === null && userID === null) ||
                          (username === "" && userID === "") ? (
                            <> {process.env.FILLOUT_ENQUIRY_FORM?
                              <div className="pull-right">
                              <Link to= {`${process.env.FILLOUT_ENQUIRY_FORM}`}
                                className="btn mx-2 btn-advisor"
                              >
                                Ask an advisor{" "}
                              </Link>
                            </div>
                              :null}</>
                           
                         
                        ) : (
                          <div className="pull-right">
                            <Button
                              className="btn btn-primary signed"
                              onClick={() => {
                                navigate("/user-profile/")
                              }}
                            >
                              {first}{" "}
                            </Button>
                            <DropdownButton id="signed" title={first}>
                              <Dropdown.Item
                                onClick={() => {
                                  navigate("/user-profile/");
                                }}
                              >
                                Dashboard
                              </Dropdown.Item>
                              <Dropdown.Item
                              onClick={() => {
                                navigate("/edit-profile/");
                              }}
                            >
                              Edit Profile
                            </Dropdown.Item>
                            <Dropdown.Item onClick={logOut}>
                              Logout
                            </Dropdown.Item>
                            </DropdownButton>
                          </div>
                        )}
                    </div>
                    <div className="menu-items">
                      <ul className="list list-unstyled">
                        <li
                          className={`list-item ${studyInFrance ? " active" : ""
                            }`}
                        >
                          <Link
                            className="nav-item"
                            to="/study-in-france/"
                            onClick={() => {
                              setStudyInFrance(true);
                              setUniversities(false);
                              setAboutUs(false);
                              setBlog(false);
                              setHousing(false)
                              setAskme(false)
                              setTools(false);
                              setServices(false)
                            }}
                          >
                            Study in France
                          </Link>
                        </li>
                        <li
                        className={`list-item ${services ? " active" : ""
                          }`}
                      >
                        <Link
                          className="nav-item"
                          to="/services/"
                          onClick={() => {
                            setServices(true);
                            setStudyInFrance(false);
                            setUniversities(false);
                            setAboutUs(false);
                            setBlog(false);
                            setHousing(false)
                            setAskme(false)
                            setTools(false)
                          }}
                        >
                          Services
                        </Link>
                      </li>
                      <li
                        className={`list-item`}
                      >
                        <Link
                          className="nav-item"
                          href={process.env.SHOP_URL}
                          target="_blank"
                          onClick={() => {
                            setServices(false);
                            setStudyInFrance(false);
                            setUniversities(false);
                            setAboutUs(false);
                            setBlog(false);
                            setHousing(false)
                            setAskme(false)
                            setTools(false)
                          }}
                        >
                          <span>Shop <sup className="blue">New</sup></span>
                        </Link>
                      </li>
                     
                        {/* <li
                          className={`list-item ${housing ? " active" : ""
                            }`}
                        >
                          <Link
                            className="nav-item"
                            to="/housing/"
                            onClick={() => {
                              setStudyInFrance(false);
                              setUniversities(false);
                              setAboutUs(false);
                              setBlog(false);
                              setHousing(true)
                              setAskme(false)
                              setTools(false)
                              setServices(false)
                            }}
                          >
                            Housing
                          </Link>
                        </li> */}
                        <li
                          className={`list-item ${universities ? " active" : ""
                            }`}
                        >
                          <Link
                            className="nav-item"
                            to="/universities/"
                            onClick={() => {
                              setStudyInFrance(false);setServices(false)
                              setUniversities(true);
                              setAboutUs(false);
                              setBlog(false);
                              setHousing(false)
                              setAskme(false)
                              setTools(false)
                            }}
                          >
                            Universities
                          </Link>
                        </li>
                        {/* <li
                          className={`list-item ${askme ? " active" : ""
                            }`}
                        >
                          <Link
                            className="nav-item"
                            href={process.env.NODEBB_URL}
                            target="_self"
                            onClick={() => {
                              setStudyInFrance(false);setServices(false)
                              setUniversities(false);
                              setAboutUs(false);
                              setBlog(false);
                              setHousing(false)
                              setAskme(true)
                              setTools(false)
                            }}
                          >
                            Community
                          </Link>
                        </li> */}
                        <li className={`list-item ${blog ? " active" : ""}`}>
                          <Link
                            className="nav-item"
                            to="/blog/"
                            onClick={() => {
                              setStudyInFrance(false);setServices(false)
                              setUniversities(false)
                              setAboutUs(false);
                              setBlog(true);
                              setHousing(false)
                              setAskme(false)
                              setTools(false)
                            }}
                          >
                            Blog
                          </Link>
                        </li>
                        <li className={`list-item ${tools ? " active" : ""}`}>
                          <DropdownButton id="signed" title={"Tools"}  className="nav-item" onClick={() => {
                              setStudyInFrance(false);
                              setUniversities(false)
                              setAboutUs(false);
                              setBlog(false);
                              setHousing(false)
                              setAskme(false)
                              setTools(true);
                              setServices(false)
                            }}>
                            {/* <Dropdown.Item
                              onClick={() => {
                                navigate("/pricing/");
                              }}
                            >
                              Pricing
                            </Dropdown.Item> */}
                            <Dropdown.Item
                              onClick={() => {
                                navigate("/tools/");
                              }}
                            >
                              Student Budget Calculator
                            </Dropdown.Item>
                          </DropdownButton>
                        </li>
                        {(username === null && userID === null) ||
                          (username === "" && userID === "") ? (
                            <> {process.env.FILLOUT_ENQUIRY_FORM?
                              <li className="pull-right">
                              <Link to= {`${process.env.FILLOUT_ENQUIRY_FORM}`}
                                className="btn mx-2 btn-advisor"
                              >
                                Ask an advisor{" "}
                              </Link>
                            </li>
                              :null}</>
                           
                         
                        ) : (
                          <li className="pull-right">
                            <Button
                              className="btn btn-primary signed"
                              onClick={() => {
                                navigate("/user-profile/")
                              }}
                            >
                              {first}{" "}
                            </Button>
                            <DropdownButton id="signed" title={first}>
                              <Dropdown.Item
                                onClick={() => {
                                  navigate("/user-profile/");
                                }}
                              >
                                Dashboard
                              </Dropdown.Item>
                              <Dropdown.Item
                              onClick={() => {
                                navigate("/edit-profile/");
                              }}
                            >
                              Edit Profile
                            </Dropdown.Item>
                            <Dropdown.Item onClick={logOut}>
                              Logout
                            </Dropdown.Item>
                            </DropdownButton>
                          </li>
                        )}
                      </ul>
                    </div>
                  </div>
                  {/* mobile menu end */}
                </div>
              </div>
            </div>
          </div>
          <div className="header sticky" id="stickyheader">
            <div className="container">
              <div className="row">
                {/* logo */}
                <div className="col-8 col-md-2 col-lg-2">
                  <div className="site-logo">
                    <Link to="/">
                      <img src={sticky_logo} alt="Sticky Logo"></img>
                    </Link>
                  </div>
                </div>
                {/* menus */}
                <div className="col-4 col-md-10 col-lg-10">
                  <nav className="nav-menus">
                    <ul className="list list-unstyled">
                      <li
                        className={`list-item ${studyInFrance ? " active" : ""
                          }`}
                      >
                        <Link
                          className="nav-item"
                          to="/study-in-france/"
                          onClick={() => {
                            setStudyInFrance(true);setServices(false)
                            setAboutUs(false);
                            setBlog(false);
                            setUniversities(false)
                            setHousing(false)
                            setAskme(false)
                            setTools(false)
                          }}
                        >
                          Study in France
                        </Link>
                      </li>
                      <li
                        className={`list-item ${services ? " active" : ""
                          }`}
                      >
                        <Link
                          className="nav-item"
                          to="/services/"
                          onClick={() => {
                            setServices(true);
                            setStudyInFrance(false);
                            setUniversities(false);
                            setAboutUs(false);
                            setBlog(false);
                            setHousing(false)
                            setAskme(false)
                            setTools(false)
                          }}
                        >
                          Services
                        </Link>
                      </li>
                      <li
                        className={`list-item`}
                      >
                        <Link
                          className="nav-item"
                          href={process.env.SHOP_URL}
                          target="_blank"
                          onClick={() => {
                            setServices(false);
                            setStudyInFrance(false);
                            setUniversities(false);
                            setAboutUs(false);
                            setBlog(false);
                            setHousing(false)
                            setAskme(false)
                            setTools(false)
                          }}
                        >
                          <span>Shop <sup className="blue">New</sup></span>
                        </Link>
                      </li>
                      {/* <li
                        className={`list-item ${housing ? " active" : ""
                          }`}
                      >
                        <Link
                          className="nav-item"
                          to="/housing/"
                          onClick={() => {
                            setStudyInFrance(false);setServices(false)
                            setAboutUs(false);
                            setBlog(false);
                            setUniversities(false)
                            setHousing(true)
                            setAskme(false)
                            setTools(false)
                          }}
                        >
                          Housing
                        </Link>
                      </li> */}
                      <li
                        className={`list-item ${universities ? " active" : ""
                          }`}
                      >
                        <Link
                          className="nav-item"
                          to="/universities/"
                          onClick={() => {
                            setStudyInFrance(false);setServices(false)
                            setAboutUs(false);
                            setBlog(false);
                            setUniversities(true)
                            setHousing(false)
                            setAskme(false)
                            setTools(false)
                          }}
                        >
                          Universities
                        </Link>
                      </li>
                      {/*<li className={`list-item ${aboutUs ? " active" : ""}`}>
                        <Link
                          className="nav-item"
                          to="/about-us/"
                          onClick={() => {
                            setStudyInFrance(false);
                            setAboutUs(true);
                            setBlog(false);
                             setUniversities(false)
                              setHousing(false)
                              setAskme(false)
                          }}
                        >
                          About Us
                        </Link>
                      </li>*/}
                      {/* <li
                        className={`list-item ${askme ? " active" : ""
                          }`}
                      >
                        <Link
                          className="nav-item"
                          href={process.env.NODEBB_URL}
                          target="_self"
                          onClick={() => {
                            setStudyInFrance(false);setServices(false)
                            setUniversities(false);
                            setAboutUs(false);
                            setBlog(false);
                            setHousing(false)
                            setAskme(true)
                            setTools(false)
                          }}
                        >
                          Community
                        </Link>
                      </li> */}
                      <li className={`list-item ${blog ? " active" : ""}`}>
                        <Link
                          className="nav-item"
                          to="/blog/"
                          onClick={() => {
                            setStudyInFrance(false);setServices(false)
                            setAboutUs(false);
                            setBlog(true);
                            setUniversities(false)
                            setHousing(false)
                            setAskme(false)
                            setTools(false)
                          }}
                        >
                          Blog
                        </Link>
                      </li>
                       <li className={`list-item ${tools ? " active" : ""}`}>
                        <DropdownButton id="signed" title={"Tools"}  className="nav-item" onClick={() => {
                            setStudyInFrance(false);
                            setUniversities(false)
                            setAboutUs(false);
                            setBlog(false);
                            setHousing(false)
                            setAskme(false)
                            setTools(true);
                            setServices(false)
                          }}>
                          {/* <Dropdown.Item
                            onClick={() => {
                              navigate("/pricing/");
                            }}
                          >
                            Pricing
                          </Dropdown.Item> */}
                          <Dropdown.Item
                            onClick={() => {
                              navigate("/tools/");
                            }}
                          >
                            Student Budget Calculator
                          </Dropdown.Item>
                        </DropdownButton>
                        </li>
                      {(username === null && userID === null) ||
                        (username === "" && userID === "") ? (
                        <>
                        {process.env.FILLOUT_ENQUIRY_FORM?
                          <li className="pull-right">
                            <Link to= {`${process.env.FILLOUT_ENQUIRY_FORM}`}
                              className="btn mx-2 btn-advisor"
                            >
                              Ask an advisor{" "}
                            </Link>
                          </li>:null}
                        </>

                        // <li className="pull-right">

                        //   <Button
                        //     className="btn btn-primary"
                        //     onClick={(e) => {
                        //       e.preventDefault();
                        //       setOpen(true);
                        //       setLogin(true);
                        //       localStorage.removeItem("redirect");
                        //       return false;
                        //     }}
                        //   >
                        //     Sign INinP{" "}
                        //   </Button>


                        // </li>
                      ) : (
                        <li className="pull-right">
                          {/* <Button
                            className="btn btn-primary signed"
                            onClick={() => {
                              navigate("/user-profile/")
                            }}
                          >
                            {username}{" "}
                          </Button> */}
                          <DropdownButton id="signed" title={first}>
                            <Dropdown.Item
                              onClick={() => {
                                navigate("/user-profile/");
                              }}
                            >
                              Dashboard
                            </Dropdown.Item>
                            <Dropdown.Item
                              onClick={() => {
                                navigate("/edit-profile/");
                              }}
                            >
                              Edit Profile
                            </Dropdown.Item>
                            <Dropdown.Item onClick={logOut}>
                              Logout
                            </Dropdown.Item>
                          </DropdownButton>
                        </li>
                      )}
                    </ul>
                  </nav>
                  {/* mobile menu */}
                  <div className="mobile-menu">
                    <div className="humburger-menu">
                      <span className="humburger-menu-click">
                        <i id="bar" className="fas fa-bars"></i>
                      </span>
                    </div>
                    <div className="menu-items">
                      <ul className="list list-unstyled">
                        <li
                          className={`list-item ${studyInFrance ? " active" : ""
                            }`}
                        >
                          <Link
                            className="nav-item"
                            to="/study-in-france/"
                            onClick={() => {
                              setStudyInFrance(true);setServices(false)
                              setAboutUs(false);
                              setBlog(false);
                              setUniversities(false)
                              setHousing(false)
                              setAskme(false)
                              setTools(false)
                            }}
                          >
                            Study in France
                          </Link>
                        </li>
                        <li
                        className={`list-item ${services ? " active" : ""
                          }`}
                      >
                        <Link
                          className="nav-item"
                          to="/services/"
                          onClick={() => {
                            setServices(true);
                            setStudyInFrance(false);
                            setUniversities(false);
                            setAboutUs(false);
                            setBlog(false);
                            setHousing(false)
                            setAskme(false)
                            setTools(false)
                          }}
                        >
                          Services
                        </Link>
                      </li>
                      <li
                        className={`list-item`}
                      >
                        <Link
                          className="nav-item"
                          href={process.env.SHOP_URL}
                          target="_blank"
                          onClick={() => {
                            setServices(false);
                            setStudyInFrance(false);
                            setUniversities(false);
                            setAboutUs(false);
                            setBlog(false);
                            setHousing(false)
                            setAskme(false)
                            setTools(false)
                          }}
                        >
                          <span>Shop <sup className="blue">New</sup></span>
                        </Link>
                      </li>
                        {/* <li
                          className={`list-item ${housing ? " active" : ""
                            }`}
                        >
                          <Link
                            className="nav-item"
                            to="/housing/"
                            onClick={() => {
                              setStudyInFrance(false);setServices(false)
                              setAboutUs(false);
                              setBlog(false);
                              setUniversities(false)
                              setHousing(true)
                              setAskme(false)
                              setTools(false)
                            }}
                          >
                            Housing
                          </Link>
                        </li> */}
                        <li
                          className={`list-item ${universities ? " active" : ""
                            }`}
                        >
                          <Link
                            className="nav-item"
                            to="/universities/"
                            onClick={() => {
                              setStudyInFrance(false);setServices(false)
                              setAboutUs(false);
                              setBlog(false);
                              setUniversities(true)
                              setHousing(false)
                              setAskme(false)
                              setTools(false)
                            }}
                          >
                            Study in France
                          </Link>
                        </li>
                        <li
                        className={`list-item ${services ? " active" : ""
                          }`}
                      >
                        <Link
                          className="nav-item"
                          to="/services/"
                          onClick={() => {
                            setServices(true);
                            setStudyInFrance(false);
                            setUniversities(false);
                            setAboutUs(false);
                            setBlog(false);
                            setHousing(false)
                            setAskme(false)
                            setTools(false)
                          }}
                        >
                          Services
                        </Link>
                      </li>
                      <li
                        className={`list-item`}
                      >
                        <Link
                          className="nav-item"
                          href={process.env.SHOP_URL}
                          target="_blank"
                          onClick={() => {
                            setServices(false);
                            setStudyInFrance(false);
                            setUniversities(false);
                            setAboutUs(false);
                            setBlog(false);
                            setHousing(false)
                            setAskme(false)
                            setTools(false)
                          }}
                        >
                          <span>Shop <sup className="blue">New</sup></span>
                        </Link>
                      </li>
                        {/*<li className={`list-item ${aboutUs ? " active" : ""}`}>
                          <Link
                            className="nav-item"
                            to="/about-us/"
                            onClick={() => {
                              setStudyInFrance(false);
                              setAboutUs(true);
                              setBlog(false);
                              setUniversities(false)
                              setHousing(false)
                              setAskme(false)
                            }}
                          >
                            About Us
                          </Link>
                        </li>*/}
                        {/* <li
                          className={`list-item ${askme ? " active" : ""
                            }`}
                        >
                          <Link
                            className="nav-item"
                            href={process.env.NODEBB_URL}
                            target="_self"
                            onClick={() => {
                              setStudyInFrance(false);setServices(false)
                              setUniversities(false);
                              setAboutUs(false);
                              setBlog(false);
                              setHousing(false)
                              setAskme(true)
                              setTools(false)
                            }}
                          >
                            Community
                          </Link>
                        </li> */}
                        <li className={`list-item ${blog ? " active" : ""}`}>
                          <Link
                            className="nav-item"
                            to="/blog/"
                            onClick={() => {
                              setStudyInFrance(false);setServices(false)
                              setAboutUs(false);
                              setBlog(true);
                              setUniversities(false)
                              setHousing(false)
                              setAskme(false)
                              setTools(false)
                            }}
                          >
                            Blog
                          </Link>
                        </li>
                        <li className={`list-item ${tools ? " active" : ""}`}>
                          <DropdownButton id="signed" title={"Tools"}  className="nav-item" onClick={() => {
                              setStudyInFrance(false);
                              setUniversities(false)
                              setAboutUs(false);
                              setBlog(false);
                              setHousing(false)
                              setAskme(false)
                              setTools(true);
                              setServices(false)
                            }}>
                            {/* <Dropdown.Item
                              onClick={() => {
                                navigate("/pricing/");
                              }}
                            >
                              Pricing
                            </Dropdown.Item> */}
                            <Dropdown.Item
                              onClick={() => {
                                navigate("/tools/");
                              }}
                            >
                              Student Budget Calculator
                            </Dropdown.Item>
                          </DropdownButton>
                        </li>
                        {(username === null && userID === null) ||
                          (username === "" && userID === "") ? (
                            <> {process.env.FILLOUT_ENQUIRY_FORM?
                              <li className="pull-right">
                              <Link to= {`${process.env.FILLOUT_ENQUIRY_FORM}`}
                                className="btn mx-2 btn-advisor"
                              >
                               Ask an advisor{" "}
                              </Link>
                            </li>
                              :null}</>
                        ) : (
                          <li className="pull-right">
                            {/* <Button
                              className="btn btn-primary signed"
                              onClick={() => {
                                navigate("/user-profile/")
                              }}
                            >
                              {username}{" "}
                            </Button> */}
                            <DropdownButton
                              id="btn btn-primary signed"
                              title={first}
                            >
                              <Dropdown.Item
                                onClick={() => {
                                  navigate("/user-profile/");
                                }}
                              >
                                Profile
                              </Dropdown.Item>
                              <Dropdown.Item onClick={logOut}>
                                Logout
                              </Dropdown.Item>
                            </DropdownButton>
                          </li>
                        )}
                      </ul>
                    </div>
                  </div>
                  {/* mobile menu end */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  );
};

Header.propTypes = {
  siteTitle: PropTypes.string,
};

Header.defaultProps = {
  siteTitle: ``,
};

export default Header;
